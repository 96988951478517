import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AstroGuru from "../assets/astrology.png";
import AstroGuru2 from "../assets/astro_color.png";
import book_puja from "../assets/book_puja.png";
import puja_color from "../assets/puja_color.png";
import yantra from "../assets/torus-yantra.png";
import yantra2 from "../assets/astro_color.png";
import My_Order from "../assets/check-out.png";
import My_Order2 from "../assets/check-out2.png";
import nametag from "../assets/name_tag.png";
import nametag2 from "../assets/name_tag2.png";
import { getCountryCode } from "../utils/helper.utils";

function BottomNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const cCode = getCountryCode();
  const isIndia = cCode === "in";

  // Determine the active item based on the current path
  const getActiveItem = () => {
    const path = location.pathname;
    if (path.startsWith("/online-puja")) return "puja";
    if (path === "/") return "puja";
    if (path.startsWith("/astro-guru")) return "astro";
    if (path.startsWith("/abhimantrit-products")) return "products";
    if (path.startsWith("/name-correction")) return "name";
    if (path.startsWith("/orders")) return "orders";
    return null;
  };

  const activeItem = getActiveItem();

  return (
    <div className="bottom-nav-bar fixed bottom-0 left-0 right-0 bg-white flex justify-between items-center px-4 h-16 shadow-md border-t border-gray-100">
      {/* Nav Items */}
      {[
        {
          key: "puja",
          label: "Puja",
          icon: book_puja,
          activeIcon: puja_color,
          link: "/online-puja",
        },
        {
          key: "astro",
          label: "Astro",
          icon: AstroGuru,
          activeIcon: AstroGuru2,
          link: "/astro-guru/find",
        },
        // Only include the Products tab if `isIndia` is true
        ...(isIndia
          ? [
              {
                key: "products",
                label: "Products",
                icon: yantra,
                activeIcon: yantra2,
                link: "/abhimantrit-products",
              },
            ]
          : []),
        {
          key: "name",
          label: "Name",
          icon: nametag,
          activeIcon: nametag2,
          link: "/name-correction/",
        },
        {
          key: "orders",
          label: "Orders",
          icon: My_Order,
          activeIcon: My_Order2,
          link: "/orders/1/puja",
        },
      ].map(({ key, label, icon, activeIcon, link }) => (
        <div
          key={key}
          className={`nav-item flex flex-col items-center cursor-pointer ${
            activeItem === key ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => navigate(link)}
        >
          <div className="w-10 h-10 flex items-center justify-center mb-1">
            <img
              src={activeItem === key ? activeIcon : icon}
              className="w-full h-full object-contain"
              alt={`${label} Icon`}
            />
          </div>
          <span
            className={`text-xs font-medium ${
              activeItem === key ? "text-orange-500" : "text-gray-500"
            }`}
          >
            {label}
          </span>
        </div>
      ))}
    </div>
  );
}

export default BottomNavBar;
