import { Routes, Route } from "react-router-dom";
import RouteType from "./Route.type";
import { defaultRoutes } from "./allRoutes";
import { HelmetProvider } from "react-helmet-async";
import { Suspense, useEffect } from "react";
import Loader from "../components/Loader";
import { getRequest } from "../utils/api.utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartCount,
  setColorsMap,
  setProducts,
} from "../redux/actions/homeAction";
import { getCountryCode } from "../utils/helper.utils";

export default function Index() {
  const dispatch = useDispatch();
  const { homeData } = useSelector((state: any) => state?.homeReducer);
  const session_id: string | null = localStorage.getItem("session_id");
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;
  let country_code = getCountryCode();

  const fallbackHomeData = () => {
    getRequest<any>(`/noauth-api/v1/home?country_code=in`)
      .then((data) => {
        // Check if banner exists and update its deep_link
        if (data?.results?.banner && Array.isArray(data.results.banner)) {
          data.results.banner = data.results.banner.map((banner:any) => ({
            ...banner,
            deep_link: null, // Set deep_link to null
          }));
        }
  
        // Dispatch the updated data
        dispatch(setProducts(data?.results));
      })
      .catch((err: unknown) => {
        console.error("Error:", err);
      });
  };


  useEffect(() => {
    getRequest<any>(`/noauth-api/v1/home?country_code=${country_code}`)
      .then((data) => {
        if(data.results?.banner.length > 0 ){
          dispatch(setProducts(data?.results));
        }else{
          fallbackHomeData()
        }
      })
      .catch((err: unknown) => {
        console.error("Error:", err);
      });
  }, [dispatch]);


  if (!homeData) return <Loader />;
  return (
    <HelmetProvider>
      <Suspense fallback={<Loader />}>
        <Routes>
          {defaultRoutes.map((route: RouteType, idx) => (
            <Route path={route.path} element={route.component} key={idx} />
          ))}
        </Routes>
      </Suspense>
    </HelmetProvider>
  );
}
