import { Layout } from "antd";
import Content from "./Content";
// import Footer from "./Footer";
import Header from "./Header";
import LeftTop from "../assets/images/top_left.png";
import RightTop from "../assets/images/temple-icon.svg";
export default function Page({
  children,
  className,
  header,
  footer,
  back,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
  header?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
  back?: any;
}) {
  let platform = localStorage.getItem("platform");
  return (
    <Layout className="h-[100dvh] bg-white">
      {platform !== "android" && (
        <>{header ? header : <Header back={back} />}</>
      )}
      <Content className={`bg-white ${className}` || ""}>{children}</Content>
      {footer && footer}
    </Layout>
  );
}
