import React, { useEffect, useState } from "react";
import Page from "../../layouts/Page";
import TrendingPujaCard from "./components/TrendingPujaCard";
import { getRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
import { Divider, Typography } from "antd";
import ChatWithUs from "../../components/generic/ChatWithUs";
import SeoHelmet from "../../components/SeoHelmet";
import PujaData from "../../data/PujaData";
import { countryCodes, getCountryCode } from "../../utils/helper.utils";
import BottomNavBar from "../../layouts/BottomNavBar";

export default function PoojaCountryTest() {
  const [poojaData, setPoojaData] = useState<any>(null);
  const [countryCode, setCountryCode] = useState("");
  const [filteredPuja, setFilteredPuja] = useState<any>(PujaData);
  
  let cCode = getCountryCode();
  
  useEffect(() => {
    getRequest(
      `/noauth-api/v1/product/poojaByCountryCode?country_code=${cCode || "in"}`
    ).then((res: any) => {
      const sortedData = sortResultsByCurrency(res.results);
      setPoojaData(sortedData);
    });
  }, []);

  // Sorting function
  const sortResultsByCurrency = (data: any) => {
    return data.sort((a: any, b: any) => {
      const aHasINR =
        a.packages && a.packages.some((pkg: any) => pkg.currency === "INR");
      const bHasINR =
        b.packages && b.packages.some((pkg: any) => pkg.currency === "INR");
      return bHasINR - aHasINR;
    });
  };

  useEffect(() => {
    if (poojaData) {
      let currencyData = countryCodes.find(
        (ct: any) => ct.country?.toLowerCase() === countryCode?.toLowerCase()
      );
      let currency = currencyData?.currency;

      let data = poojaData.filter(
        (puj: any) => puj?.packages[0].currency === currency
      );

      setFilteredPuja(data);
    } else {
      setFilteredPuja(poojaData);
    }
  }, [countryCode, poojaData]);

  if (!poojaData) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  return (
    <Page>
      <SeoHelmet
        title="Sacred Vedic Puja Services: Browse Listings for Your Need"
        description="Browse through our collection of sacred puja services, designed to cater to your spiritual requirements. Explore various listings, ranging from traditional rituals to contemporary practices. Connect with vedic priest and discover authentic puja experiences tailored to meet your needs. Start your spiritual journey today."
      />
      
      <ChatWithUs />
      <div>
        <Typography.Text className="text-center block mb-2 mt-8 text-lg font-semibold">
          Explore Upcoming Pujas{" "}
        </Typography.Text>
        <Typography.Text type="secondary" className="block text-center px-4">
          Discover and participate in our upcoming spiritual ceremonies for
          enhanced blessings and divine connection.
        </Typography.Text>
        
        {/* Puja Cards */}
        <div className="flex flex-col justify-center items-center w-full">
          {poojaData?.map((item: any) => {
            return (
              <div className="mt-4 w-[92%]" key={item.id}>
                <TrendingPujaCard width="w-full" item={item} />
              </div>
            );
          })}
        </div>
      </div>

      {poojaData && <div className="h-20" />}
      <BottomNavBar />
    </Page>
  );
}