import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLongLeftIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Divider, message, Typography } from "antd";
import { useSelector } from "react-redux";
import { getRequest } from "../utils/api.utils";
import LogoIMAGE from "../assets/images/logo_image.jpeg";
import fav_logo from "../assets/fav_logo.png";

import SocialMediaBar from "../components/SocialMediaBar";
import { fetchUserCountry, getCountryCode } from "../utils/helper.utils";
const { Text } = Typography;

interface PageHeaderProps {
  title?: string;
  subTitle?: string;
  goBack?: boolean;
  back?: boolean;
  noBack?: boolean;
}

let navLinks = [
  { label: "Home", link: "/" },
  { label: "Upcoming Puja", link: "/online-puja" },
  {
    label: "Abhimantrit Products",
    link: "/abhimantrit-products",
    showForCountry: "in",
  },
  { label: "Name Correction", link: "/name-correction" },
  { label: "Puja Services", link: "/online-puja" },
  { label: "My Orders", link: "/orders/1/puja" },
];

export default function Header({ title, noBack, back }: PageHeaderProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const cCode = getCountryCode();

  // Filter buttons based on `showForCountry`
  navLinks = navLinks.filter(
    (navLink) => !navLink.showForCountry || navLink.showForCountry === cCode
  );

  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  const { cartCount } = useSelector((state: any) => state?.homeReducer);
  const { homeData } = useSelector((state: any) => state?.homeReducer);
  const [searchBox, setSearchBox] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<any>("");

  const [searchResults, setSearchResults] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (searchQuery && isMounted) {
      setIsLoading(true);

      getRequest<any>(`/noauth-api/v1/search?params=${searchQuery}`)
        .then((data) => {
          setIsLoading(false);
          setSearchResults(data?.results);
        })
        .catch((err: unknown) => {
          console.error("Error fetching products:", err);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [searchQuery]);

  const toggleSearchBox = () => {
    setSearchBox(!searchBox);
  };

  const [showPatti, setShowPatti] = useState<boolean>(true);
  const topPattiClose = sessionStorage.getItem("topPattiClose");

  return (
    <div
      className={`bg-white ${
        homeData &&
        homeData?.promoted_message &&
        topPattiClose !== "yes" &&
        showPatti
          ? "h-[10vh]"
          : "h-[6vh]"
      } `}
    >
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 " onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  {/* <button
                    type="button"
                    className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  <div className="flex items-center w-full justify-between mb-4">
                    <img src={LogoIMAGE} alt="DevPunya Logo" className="h-10" />
                    <XMarkIcon
                      className="h-7 w-7 p-1 block bg-orange-500 rounded-full cursor-pointer text-white"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                </div>
                <div className="">
                  {/* Navigation Items */}
                  <div className="">
                    {navLinks.map((item, index) => (
                      <div
                        key={index}
                        className="text-lg cursor-pointer border-b py-5  border-orange-100"
                        onClick={() => {
                          navigate(item.link);
                          setOpen(false);
                        }}
                      >
                        <span className="px-4">{item.label}</span>
                        {/* <Divider  className="border-orange-100"/> */}
                      </div>
                    ))}
                  </div>

                  {/* )} */}

                  <div className="px-4 mt-6">
                    {user ? (
                      <div
                        onClick={async () => {
                          localStorage.clear();
                          setOpen(false);
                          await fetchUserCountry();

                          message.success("Logout successfully!");
                        }}
                        className="w-full mb-2"
                      >
                        <Typography.Text className="text-lg text-red-600">
                          Logout
                        </Typography.Text>
                      </div>
                    ) : (
                      <div
                        onClick={() => navigate("/auth")}
                        className="w-full mb-2"
                      >
                        <Typography.Text className="text-lg">
                          Login
                        </Typography.Text>
                      </div>
                    )}
                  </div>

                  <Divider />

                  <div>
                    <SocialMediaBar />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative h-full ">
        {homeData &&
          homeData?.promoted_message &&
          topPattiClose !== "yes" &&
          showPatti && (
            <div className="w-full flex h-8 items-center justify-between bg-primary">
              <p className="font-light text-center w-full text-xs text-white">
                {homeData?.promoted_message?.description}{" "}
              </p>

              <div className=" border rounded-full w-6 h-6 flex items-center justify-center mr-4">
                <span
                  onClick={() => {
                    sessionStorage.setItem("topPattiClose", "yes");
                    setShowPatti(false);
                  }}
                  className="text-white"
                >
                  X
                </span>
              </div>
            </div>
          )}
        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8"
        >
          <div className="flex items-center justify-between ">
            <div className="flex h-12 items-center">
              {!noBack && (
                <button
                  type="button"
                  className="relative  mr-4 text-gray-600  lg:hidden"
                  onClick={() => (back ? navigate(-1) : setOpen(true))}
                >
                  {back ? (
                    <ArrowLongLeftIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                  )}
                </button>
              )}
              {/* Logo */}
              <div className="ml-2 flex lg:ml-0">
                <h1>
                  {title ? (
                    <Text ellipsis>
                      {title?.length > 24 ? title?.slice(0, 24) + "..." : title}
                    </Text>
                  ) : (
                    <Typography.Text
                      onClick={() => navigate("/home")}
                      className="flex items-center text-xl text-primary"
                    >
                      <img
                        className="h-[100%] w-[150px] object-contain ml-[-20px]"
                        src={LogoIMAGE}
                        alt="dev punya"
                      />
                    </Typography.Text>
                  )}
                </h1>
              </div>
            </div>

            {user ? (
              <div className="flex items-center">
                <div
                  onClick={() => {
                    navigate(`/orders/1/puja`);
                  }}
                  // border border-orange-500
                  className=" text-sm p-1 rounded-lg bg-orange-500  px-2 py-1 text-white font-semibold"
                >
                  My Bookings
                </div>
              </div>
            ) : (
              <div
                onClick={() => navigate("/auth")}
                className=" text-sm p-1 rounded-lg bg-orange-500  px-2 py-1 text-white font-semibold"
              >
                LOGIN
              </div>
            )}
          </div>
        </nav>
      </header>
    </div>
  );
}
