import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PoojaCarousel.css";
import { Modal } from "antd";
import Next from "../../assets/images/chevron.png";
import Left from "../../assets/images/left-chevron.png";
import { CloseCircleOutlined } from "@ant-design/icons";

const PoojaCarousel = ({ product, setBgBlur }) => {
  const sliderRef = useRef(null);
  const [modalOpen, setModalOpen] = useState("");
  const [activeSlide, setActiveSlide] = useState(0);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Transparent white background
          padding: 4,
          width: "25px",
          height: "25px",
          color: "white",
          borderRadius: "50%",
          zIndex: 100,
          cursor: "pointer",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
        }}
        onClick={onClick}
      >
        <img src={Next} alt="Next Arrow" />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    if(activeSlide === 0) return <></>

    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Transparent white background
          padding: 4,
          width: "25px",
          height: "25px",
          color: "white",
          borderRadius: "50%",
          zIndex: 100,
          cursor: "pointer",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
        }}
        onClick={onClick}
      >
        <img src={Left} alt="Previous Arrow" />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: !modalOpen,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => setActiveSlide(current),
    beforeChange: (current, next) => sliderRef.current.slickGoTo(next),
  };

  return (
    <div>
      {/* Modal Section */}
      <Modal
        centered
        footer={null}
        closable={false}
        open={Boolean(modalOpen)}
        onCancel={() => {
          setModalOpen("");
          setBgBlur(false);
        }}
      >
        <div
          onClick={() => {
            setModalOpen("");
            setBgBlur(false);
          }}
          className="absolute right-2 top-1"
        >
          <CloseCircleOutlined />
        </div>
        <Slider ref={sliderRef} {...settings} initialSlide={activeSlide}>
          {product?.images?.map((img, index) => (
            <div key={index} className={``}>
              <img
                src={img}
                className={`object-contain h-full`}
                alt={`${index}`}
                onClick={() => {
                  setBgBlur(true);
                  setModalOpen(img);
                }}
              />
            </div>
          ))}
        </Slider>
      </Modal>

      {/* Main Slider Section */}
      <Slider ref={sliderRef} {...settings}>
        {product.images.map((img, index) => (
          <div key={index} className="bg-orange-100 ">
            <img
              src={img}
              className={`h-[200px]  object-contain`}
              alt={product.name}
              onClick={() => {
                setBgBlur(true);
                setModalOpen(img);
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PoojaCarousel;