import React, { useState } from "react";
import {
  Checkbox,
  Button,
  List,
  Typography,
  message,
  Divider,
  Input,
  Modal,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import CategoryGrid from "./CategoryGrid";
import Page from "../../layouts/Page";
import { useLocation, useNavigate } from "react-router-dom";
import cover from "../../assets/categoires_astro/ask_astroguru_banner.jpeg";
import { categoryQuestions } from "./categoryQuestions";
import Footer from "../../layouts/Footer";
import { useSelector } from "react-redux";
import PoojaCarousel from "../pooja/PoojaCarousel";
import { getCountryCode } from "../../utils/helper.utils";

function AstroGuruFind() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const cCode = getCountryCode();

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsJson, setQuestionsJson] = useState({});
  const [activeCategory, setActiveCategory] = useState(
    state?.key ? state?.key : "career"
  );

  const [customQuestion, setCustomQuestion] = useState("");
  const { homeData } = useSelector((state) => state?.homeReducer);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const quePrice = cCode === "in" ? 350 : 10;

  const calculateTotal = () => {
    if (cCode === "in") {
      return selectedQuestions.length * quePrice;
    }

    if (selectedQuestions.length <= 2) {
      return selectedQuestions.length * quePrice;
    } else {
      const freeQuestions = Math.floor(selectedQuestions.length / 3);
      const paidQuestions = selectedQuestions.length - freeQuestions;
      return paidQuestions * 10;
    }
  };

  const handleSelection = (question, category = activeCategory) => {
    const isSelected = selectedQuestions.includes(question);
    let updatedQuestionsJson = { ...questionsJson };

    if (isSelected) {
      setSelectedQuestions(
        selectedQuestions?.filter((item) => item !== question)
      );
      updatedQuestionsJson[category] = updatedQuestionsJson[category]?.filter(
        (item) => item !== question
      );
    } else {
      setSelectedQuestions([...selectedQuestions, question]);
      if (!updatedQuestionsJson[category]) {
        updatedQuestionsJson[category] = [];
      }
      updatedQuestionsJson[category] = [
        ...updatedQuestionsJson[category],
        question,
      ];
    }
    if (updatedQuestionsJson[category]?.length === 0) {
      delete updatedQuestionsJson[category];
    }
    setQuestionsJson(updatedQuestionsJson);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addCustomQuestion = () => {
    if (!customQuestion) return;
    handleSelection(customQuestion, "other");
    setCustomQuestion("");
  };

  let currency = cCode === "in" ? "₹" : "$";

  const submitHandler = async () => {
    if (selectedQuestions.length === 0) {
      message.error("Select at least one question!");
      return;
    }

    navigate("/astro-guru/form", {
      state: {
        selectedQuestions,
        questionsJson,
        totalAmount: calculateTotal().toFixed(2),
        currency_code: cCode === "in" ? "INR" : "USD",
        currency
      },
    });
  };

  let banners = homeData.banner?.filter((item) => item.page === "astro_guru");
  let bannersData = banners?.map((item) => item.url);
  return (
    <Page>
      <PoojaCarousel
        product={{
          name: "",
          images: bannersData?.length > 0 ? bannersData : [cover],
        }}
        setBgBlur={() => {}}
      />

      <div className="bg-white min-h-screen px-4">
        <div className="max-w-3xl mx-auto bg-white rounded-lg p-1">
          <h2 className="text-xl font-semibold text-center">
            Get Answers from our expert{" "}
            <span className="text-orange-500">AstroGuru</span>
          </h2>

          <div className="mt-3">
            <h2 className="text-xl font-semibold text-center">
              Ask 1 question @{" "}
              <span className="text-green-500">
                {currency}
                {quePrice}
              </span>
            </h2>
          </div>

          {cCode !== "in" && (
            <Typography.Text className="my-3 text-center text-xl block text-orange-600 font-semibold">
              Ask 2 questions and get 1 free! 🎉
            </Typography.Text>
          )}

          <div className="flex justify-between mt-3 mb-3">
            <Button
              type="primary"
              size="large"
              className="bg-orange-600 text-white"
              onClick={() => navigate(`/name-correction/`)}
            >
              Name Correction
            </Button>

            <Button
              type="primary"
              size="large"
              className="bg-orange-600 text-white"
              onClick={handleOpen}
            >
              Type Your Question
            </Button>
          </div>

          <Modal
            title="Type Your Question"
            visible={open}
            onCancel={handleClose}
            onOk={addCustomQuestion}
            okText="Pay Now"
            okButtonProps={{
              style: { backgroundColor: "green", color: "white" },
              onClick: submitHandler,
            }}
          >
            <Input
              className="mt-6"
              placeholder="Type your question here..."
              value={customQuestion}
              onChange={(e) => setCustomQuestion(e.target.value)}
              onPressEnter={addCustomQuestion}
              suffix={
                <Button type="link" onClick={addCustomQuestion}>
                  Add
                </Button>
              }
            />

            <div className="mt-6 bg-gray-100 p-4 rounded-lg">
              <h3 className="font-semibold text-lg">
                Questions for Ask an AstroGuru
              </h3>
              <List
                dataSource={selectedQuestions}
                renderItem={(item) => (
                  <List.Item
                    className="flex justify-between items-center"
                    actions={[
                      <div className="flex items-center space-x-4">
                        <span className="text-gray-500">₹{quePrice}</span>
                        <CloseCircleOutlined
                          className="text-red-500"
                          onClick={() => handleSelection(item, "other")}
                        />
                      </div>,
                    ]}
                  >
                    <p>{item}</p>
                  </List.Item>
                )}
                footer={
                  <div className="mt-4 space-y-2">
                    <div className="flex justify-between font-semibold text-lg">
                      <span>Total Amount:</span>
                      <span>
                        {currency}
                        {calculateTotal().toFixed(2)}
                      </span>
                    </div>
                  </div>
                }
              />
            </div>
          </Modal>

          <Typography.Text type="secondary" className="mb-4 block">
            Get personalized remedies, puja, and gemstone recommendations from
            AstroGuru within 48 hours. Submit your questions, birth details, and
            contact info to receive solutions based on your kundali.
          </Typography.Text>

          <CategoryGrid
            setActiveCategory={setActiveCategory}
            activeCategory={activeCategory}
          />

          <div className="border p-2 mt-6 rounded-lg">
            <Typography.Text className="text-2xl">
              {activeCategory.charAt(0).toUpperCase() + activeCategory.slice(1)}
            </Typography.Text>
            <div className="grid grid-cols-1 gap-4 mt-2">
              {categoryQuestions[activeCategory].map((question, index) => (
                <div className="border rounded-lg" key={index}>
                  <Checkbox
                    onChange={() => handleSelection(question)}
                    className={`p-4 rounded-lg ${
                      selectedQuestions.includes(question)
                        ? "bg-orange-100"
                        : ""
                    }`}
                    checked={selectedQuestions.includes(question)}
                  >
                    {question}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 absolute bottom-0 left-0 right-0 bg-white">
            {cCode !== "in" && selectedQuestions.length > 0 && selectedQuestions.length < 4 && (
              <Typography.Text className="text-center block text-orange-500 font-semibold py-2">
                {selectedQuestions.length < 3
                  ? `Select ${
                      3 - selectedQuestions.length
                    } more questions to get 1 free!`
                  : "Great! You get free question!"}
              </Typography.Text>
            )}

            {selectedQuestions?.length !== 0 && (
              <div
                className={`p-1 w-full ${
                  selectedQuestions?.length === 0
                    ? "bg-gray-400"
                    : "bg-green-500"
                } `}
              >
                <Button
                  size="large"
                  className="w-full border-none text-white font-medium "
                  onClick={submitHandler}
                  disabled={selectedQuestions?.length === 0}
                >
                  Ask AstroGuru Expert (
                  <span>
                    {currency}
                    {calculateTotal().toFixed(2)}
                  </span>
                  )
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
}

export default AstroGuruFind;
