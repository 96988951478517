import React, { useState } from "react";
import {
  Input,
  Button,
  DatePicker,
  Select,
  Form,
  message,
  Divider,
  TimePicker,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  LockOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import Page from "../../layouts/Page";
import { postRequest } from "../../utils/api.utils";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../components/Loader";
import dayjs from "dayjs";
import Label from "../../components/Label";
import CustomTimePicker from "../../components/CustomTimePicker";
import CustomDatePicker from "../../components/CustomDatePicker";
import { setDateTimeWithToday, transformDate } from "../../utils/helper.utils";
import { AstorGuruBookingStartInterKartEvent } from "../../events/interkartEvents";

const { Option } = Select;

function AstroGuruBookingForm() {
  const { state, search } = useLocation();
  const params = new URLSearchParams(search);
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null;

  let type = params.get("type");

  const [name, setName] = useState(user?.userDetails?.fullname || "");
  const [email, setEmail] = useState(user?.userDetails?.email || "");
  const [phone, setPhone] = useState(user?.userDetails?.phone || "");
  const [gender, setGender] = useState("");
  const [fatherName, setfatherName] = useState("");
  const [husbandName, setHusbandName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [timeOfBirth, setTimeOfBirth] = useState("");
  const [amPm, setAmPm] = useState("AM");
  const [birthCity, setBirthCity] = useState("");
  const [birthCountry, setBirthCountry] = useState("");
  const [loading, setLoading] = useState(false);

  // Handler functions to update state
  const handleNameChange = (e) => setName(e.target.value);

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleFatherNamesChange = (e) => {
    setfatherName(e.target.value);
  };

  const handleHusbandNameChange = (e) => {
    setHusbandName(e.target.value);
  };
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleDateOfBirthChange = (date, dateString) => {
    setDateOfBirth(dateString);
    console.log(dateString);
  };

  const handleTimeOfBirthChange = (e) => setTimeOfBirth(e.target.value);
  const handleAmPmChange = (value) => setAmPm(value);
  const handleBirthCityChange = (e) => setBirthCity(e.target.value);
  const handleBirthCountryChange = (e) => setBirthCountry(e);

  const navigate = useNavigate();

  // Form submission handler
  const handleSubmit = async () => {
    // Handle form submission logic here

    if (!state?.totalAmount) {
      navigate(-1);
      return;
    }
    if (!name) {
      message.error("Name is required");
      return;
    }

    if (!phone) {
      message.error("Phone number is required");
      return;
    }

    if (!birthCity) {
      message.error("Place of birth is required");
      return;
    }

    if (!dateOfBirth?.day || !timeOfBirth?.hour) {
      message.error("Date of birth  & Time of birth is required");
      return;
    }

    const formData = {
      name,
      email,
      mobile: phone,
      gender, // Include gender in the form data
      ...(gender === "female" && husbandName && { husband_name: husbandName }), // Include husband's name if female
      father_name: fatherName, // Father's name should always be included
      ...(dateOfBirth &&
        dateOfBirth?.day &&
        dateOfBirth?.year &&
        dateOfBirth?.month && { dob: transformDate(dateOfBirth) }),
      tob: `${timeOfBirth?.hour}:${timeOfBirth?.minute} ${timeOfBirth?.period}`,
      pob: birthCity,
      amount: parseInt(state?.totalAmount),
      question: state?.questionsJson,
      currency_code: state?.currency_code,
    };

    setLoading(true);

    await postRequest(`/noauth-api/v1/login/user/getotp`, {
      phone: phone,
    })
      .then(async (data) => {
        await postRequest("/noauth-api/v1/login/user/verifyotp", {
          otp: "2262",
          phone: phone,
          session_id: data?.data?.results?.session_id,
        }).then(async (res) => {
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res?.data?.results })
          );

          try {
            const res2 = await postRequest(
              `/api/v1/ask_astroguru/askAstroGuruOrder`,
              formData,
              res?.data?.results.token
            );

            if (res2.data?.results?.order_id) {
              pay(res2.data?.results?.order_id);

              setLoading(false);

              AstorGuruBookingStartInterKartEvent(res?.data?.results, {});
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const pay = async (orderId) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      ...(orderId && { order_id: orderId }),
      handler: async function (response) {
        if (response?.razorpay_order_id) {
          try {
            await postRequest("/noauth-api/v1/payment/paymentOrderUpdate", {
              ...response,
              order_id: orderId,
            });
          } catch (error) {}

          message.success("Order placed successfully!");

          navigate(`/success-page/${orderId}/astro/astro-guru`);
        }
        setLoading(false);
      },
      prefill: {
        contact: `${"91"}${phone}`,
        email: email || "devpunyahome@gmail.com",
      },
      notes: {
        address: "India",
      },
      theme: {
        color: "#FFA500",
      },
      modal: {
        ondismiss: async function () {
          setLoading(false);
          // orderId &&   await postRequest("/api/v1/payment/paymentOrderUpdate", {
          //   order_id: orderId,
          // });
        },
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", async function (response) {
      await postRequest("/noauth-api/v1/payment/paymentOrderUpdate", {
        ...response.error,
        ...response.error.metadata,
        order_id: orderId,
      });

      navigate(`/success-page/${orderId}/astro/astro-guru`);
      setLoading(false);
    });

    paymentObject.open();
  };

  let title = "Submit Your Details";
  let subTitle = `Get Remedial Solutions recommended by expert AstroGuru within 48
            hours on Whatsapp and Email.`;

  if (type === "name_correction") {
    title = `Submit Details`;
    subTitle = `Our Expert Numerologist will share upto 3 name suggestions on WhatsApp  & Email within 48-72 hours`;
  }
  return (
    <Page>
      <div className="bg-white  h-screen">
        <div className=" w-full rounded-lg p-4">
          <h2 className="text-xl font-semibold text-center">{title}</h2>
          <p className="text-gray-500 text-center mb-4">{subTitle}</p>

          <Form layout="vertical">
            <Label>
              What is your name? <span className="text-red-500">*</span>
            </Label>
            <Form.Item style={{ marginBottom: "8px" }}>
              <Input
                size="large"
                placeholder="Name"
                prefix={<UserOutlined />}
                className="mb-2"
                value={name}
                onChange={handleNameChange}
              />
            </Form.Item>

            {/* <div>
              <Label>
                What is your Gender? <span className="text-red-500">*</span>
              </Label>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    className="form-radio"
                  />
                  <span className="ml-2">Male</span>
                </label>
                <label className="inline-flex items-center ml-4">
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    className="form-radio"
                  />
                  <span className="ml-2">Female</span>
                </label>
              </div>
            </div>

            <Label>
              What is your Father's name?{" "}
              <span className="text-red-500">*</span>
            </Label>
            <Form.Item style={{ marginBottom: "8px" }}>
              <Input
                size="large"
                placeholder="Name"
                prefix={<UserOutlined />}
                className="mb-2"
                value={name}
                onChange={handleNameChange}
              />
            </Form.Item> */}

            <div>
              <div>
                <label>
                  What is your Gender? <span className="text-red-500">*</span>
                </label>
                <div className="mt-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      className="form-radio"
                      onChange={handleGenderChange}
                    />
                    <span className="ml-2">Male</span>
                  </label>
                  <label className="inline-flex items-center ml-4">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      className="form-radio"
                      onChange={handleGenderChange}
                    />
                    <span className="ml-2">Female</span>
                  </label>
                </div>
              </div>

              <div>
                <label>
                  What is your Father's name?{" "}
                  <span className="text-red-500">*</span>
                </label>
                <Form.Item style={{ marginBottom: "8px" }}>
                  <Input
                    size="large"
                    placeholder="Father's Name"
                    prefix={<UserOutlined />}
                    className="mb-2"
                    value={fatherName}
                    onChange={handleFatherNamesChange}
                  />
                </Form.Item>
              </div>

              {gender === "female" && (
                <div>
                  <label>What is your Husband's name?</label>
                  <Form.Item style={{ marginBottom: "8px" }}>
                    <Input
                      size="large"
                      placeholder="Husband's Name"
                      prefix={<UserOutlined />}
                      className="mb-2"
                      value={husbandName}
                      onChange={handleHusbandNameChange}
                    />
                  </Form.Item>
                </div>
              )}
            </div>

            <Label>What is your email?</Label>
            <Form.Item style={{ marginBottom: "8px" }}>
              <Input
                size="large"
                placeholder="Email"
                prefix={<MailOutlined />}
                className="mb-2"
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Item>

            <Label>
              What is your Whatsapp number?{" "}
              <span className="text-red-500">*</span>
            </Label>
            <Form.Item style={{ marginBottom: "8px" }}>
              <Input
                size="large"
                placeholder="Whatsapp Number"
                prefix={<PhoneOutlined />}
                className="mb-2"
                value={phone}
                onChange={handlePhoneChange}
              />
            </Form.Item>

            <Label>
              What is your Date Of Birth?{" "}
              <span className="text-red-500">*</span>
            </Label>

            <CustomDatePicker
              className="mb-4 mt-2"
              onChange={(e) => {
                setDateOfBirth(e);
              }}
            />

            <Label>
              What is your Time Of Birth?{" "}
              <span className="text-red-500">*</span>
            </Label>
            <CustomTimePicker
              className="mb-4 mt-2"
              onChange={(e) => {
                setTimeOfBirth(e);
              }}
            />

            <Label>
              What is your birth place? <span className="text-red-500">*</span>{" "}
            </Label>
            <Form.Item style={{ marginBottom: "8px" }}>
              <Input
                size="large"
                placeholder="Birth City (ex: Jaipur, Rajasthan)"
                prefix={<EnvironmentOutlined />}
                className="mb-2"
                value={birthCity}
                onChange={handleBirthCityChange}
              />
            </Form.Item>
          </Form>

          <div
            className={`mt-6 p-1 w-full bg-green-500 absolute bottom-0 left-0 right-0`}
          >
            <Button
              size="large"
              icon={<LockOutlined />}
              loading={loading}
              className="w-full border-none text-white font-medium "
              onClick={handleSubmit}
            >
              Pay Now ({state?.currency}
              {parseInt(state?.totalAmount)}/-)
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default AstroGuruBookingForm;
