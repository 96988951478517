import axios from "axios";
import bcrypt from "bcryptjs";
import { sha256, sha224 } from "js-sha256";

declare global {
  interface Window {
    fbq: any;
  }
}

const session_id: any = localStorage.getItem("session_id")
  ? JSON.parse(localStorage.getItem("session_id") || "")
  : "";
const user: any = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") || "")
  : "";
const location_info: any = localStorage.getItem("location_info")
  ? JSON.parse(localStorage.getItem("location_info") || "")
  : "";

let pincodeData = location_info && location_info.find((item: any) =>
  item.types?.includes("postal_code")
);
let countryData = location_info && location_info.find((item: any) =>
  item.types?.includes("country")
);
let stateData = location_info && location_info.find((item: any) =>
  item.types?.includes("administrative_area_level_1")
);

let cityData = location_info && location_info.find((item: any) =>
  item.types?.includes("administrative_area_level_2")
);

export const addToCartPX = (data:any) => {
  const eventData = {
    content_type:data.type,
    content_ids:[data.pooja_id],
    content_name:data.name,
    currency:data.currency_code?.toUpperCase() || "INR",
    value:data.totalPrice,
    event_id:session_id || "",
    num_adults:Number(data.package_id)
  };

  console.log(eventData, "AddToCart");

  // window.fbq("track", "AddToCart", eventData);
  sendFbEvent("AddToCart", eventData);
};

export const openSankalpFormPX = (data:any) => {
  const eventData = {
    content_type:data.type,
    content_ids:[data.pooja_id],
    content_name:data.name,
    currency:data.currency_code?.toUpperCase() || "INR",
    value:data.totalPrice,
    event_id:session_id || "",
    num_adults:Number(data.package_id)
  };

  console.log(eventData, "OpenSankalpForm");

  // window.fbq("track", "AddToCart", eventData);
  sendFbEvent("OpenSankalpForm", eventData);
};



export const proceedToBackPX = (data:any) => {
  const eventData = {
    content_type:data.type,
    content_ids:[data.pooja_id],
    content_name:data.name,
    currency:data.currency_code?.toUpperCase() || "INR",
    value:data.totalPrice,
    event_id:session_id || "",
    num_adults:Number(data.package_id)
  };

  console.log(eventData, "CloseSankalpForm");

  // window.fbq("track", "AddToCart", eventData);
  sendFbEvent("CloseSankalpForm", eventData);
};


export const purchasePX = (
  orderID?: string,
  value?: number,
  content_type?: string,
  content_name?: string,
  content_ids?: string[],
  num_adults?: string
) => {
  const eventData = {
    content_type,
    content_ids,
    content_name,
    value: value || 0,
    currency: "INR",
    num_adults: num_adults,
    event_id: session_id || "",
  };

  const eventDataMeta = {
    content_type,
    content_ids,
    content_name,
    value: value || 0,
    currency: "INR",
  };

  sendFbEvent("Purchase", eventData);
};

export const purchaseAbhimantritProductPX = (
  orderID?: string,
  value?: number, // price
  content_type?: string, // like puja, gemstone, abhimnantrit priodcut etc
  content_name?: string, // name of product
  content_ids?: string[], // ids of content if one then pass also in array
  num_adults?: string
) => {
  const eventData = {
    content_type,
    content_ids,
    content_name,
    value: value || 0,
    currency: "INR",
    num_adults: num_adults,
  };

  sendFbEvent("Purchase", eventData);
};

// View content
export const viewContentPx = () => {
  const eventData = {
    content_category: "On Landing",
    content_name: "On First Render",
  };

  sendFbEvent("ViewContent", eventData);
};

export const leadPX = () => {
  const eventData = {
    event_id: session_id || "",
  };

  sendFbEvent("Lead", eventData);
};

export const initiateCheckoutPX = (
  content_category: string,
  value?: number,
  contents?: string,
  content_ids?: string[]
) => {
  const eventData = {
    content_category,
    content_ids,
    contents,
    value: value || 0,
    currency: "INR",
  };

  sendFbEvent("InitiateCheckout", eventData);
};

export const sendFbEvent = async (event_name: string, event_data: any) => {
  const session_id: any = localStorage.getItem("session_id");
  const user: any = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null;

  function getFBPCookie() {
    const cookies = document.cookie.split(";");
    const fbpCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("_fbp=")
    );
    return fbpCookie ? fbpCookie.split("=")[1] : null;
  }

  function getFBCCookie() {
    const cookies = document.cookie.split(";");
    const fbpCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("_fbc=")
    );
    return fbpCookie ? fbpCookie.split("=")[1] : null;
  }

  const fbpValue = getFBPCookie();
  const fbcValue = getFBCCookie();

  const TOKEN =
    "EAAzTkDVxRN4BO3qcxnTaZAlvMsVmKuGKwr1RqfgDVy9SGSbIZBoLvdCCPzPkTaxZBMx87AOZBVqJYeZAekzZATpUFmoiHqvM1pzZAczHZCUwJ8WFoSs78FCx5Cbn8YvUElDeyT4E4pzHqF2oH2Y4PnqFbN6UHuzEx9nLlriNmDTlNbTDyVAG3AI0P3fK8B9ePKnTbAZDZD";
  const PIXEL_ID = "1919239465176771";
  const API_VERSION = "";
  var seconds = new Date().getTime() / 1000;

  let res: any = null;
  // try {
  //   res = await axios("https://geolocation-db.com/json/");
  // } catch (error) {}

  const reqBody = {
    event_name: event_name,
    action_source: "website",
    event_time: seconds?.toFixed(0),
    event_id: JSON.parse(session_id),
    event_source_url: window.location.href,
    user_data: {
      external_id: [sha256(JSON.parse(session_id))],
      fbp: fbpValue,
      fbc: fbcValue,
      ...(user?.userDetails?.email && {
        em: [sha256(user.userDetails?.email)],
      }),
      ...(user?.userDetails?.phone && {
        ph: [sha256(user.userDetails?.phone)],
      }),
      ...(user?.userDetails?.fullname && {
        fn: [sha256(user.userDetails?.fullname)],
      }),
      ...(user?.userDetails?.fullname && {
        ln: [sha256(user.userDetails?.fullname)],
      }),
      // ...(res?.data &&
      //   res?.data?.IPv4 && { client_ip_address: res?.data?.IPv4 }),
      ...(countryData?.formatted_address && {
        country: sha256(countryData?.formatted_address),
      }),
      ...(cityData.address_components &&
        cityData.address_components?.length > 0 &&
        cityData.address_components[0].long_name && {
          ct: sha256(cityData.address_components[0].long_name), // CITY
        }),
      ...(stateData.address_components &&
        stateData.address_components?.length > 0 &&
        stateData.address_components[0].long_name && {
          st: sha256(stateData.address_components[0].long_name), // STATE
        }),
      ...(pincodeData.address_components &&
        pincodeData.address_components?.length > 0 &&
        pincodeData.address_components[0].long_name && {
          zp: sha256(pincodeData.address_components[0].long_name), // ZipCode
        }),
      ...(window.navigator.userAgent && {
        client_user_agent: window.navigator.userAgent,
      }),
    },
    ...(event_data?.num_adults && {
      custom_data: {
        num_adults: event_data?.num_adults,
      },
    }),
    ...(event_data.currency && { currency: event_data.currency }),
    ...(event_data.value && { value: event_data.value }),
    ...(event_data.content_category && {
      content_category: event_data.content_category,
    }),
    ...(event_data.content_name && { content_name: event_data.content_name }),
  };

  const reqBodyFBQ = {
    event: event_name,
    eventID: JSON.parse(session_id),
    external_id: [sha256(JSON.parse(session_id))],
    currency: "INR",
    value: event_data.value,
    content_type: event_data.content_type || "",
    content_ids: event_data?.content_ids || [],
    content_name: event_data?.content_name || "",
    content_category: event_data?.content_category || "Puja",
  };

  window.fbq("track", event_name, reqBodyFBQ);

  await axios
    .post(
      `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}`,
      {
        data: [reqBody],
      }
    )
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });
};
